// @flow

import React from "react";
import { AssignmentLayout, Layout, withPrivateRoute } from "@containers";
import { VisitForm } from "@containers";

const EditVisit = (props: *) => (
    <Layout {...props}>
        <AssignmentLayout title="Inspectiepunt bewerken" hideFooterDetails>
            <VisitForm {...props} />
        </AssignmentLayout>
    </Layout>
);

export default withPrivateRoute(
    EditVisit,
    "/opdrachten/:inspectionId/inspectiepunten/bewerken",
);
